<template>
  <div id="app">
    <!-- Przezroczyste menu nawigacyjne -->
    <header class="fixed top-0 left-0 w-full z-20">
      <!-- Rozmyte tło -->
      <div
        class="absolute inset-0 bg-gradient-to-b from-white/30 to-transparent z-0"
        style="
          backdrop-filter: blur(5px);
          -webkit-backdrop-filter: blur(5px);
          mask-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 1) 85%,
            rgba(0, 0, 0, 0) 100%
          );
          -webkit-mask-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 1) 85%,
            rgba(0, 0, 0, 0) 100%
          );
        "
      ></div>

      <!-- Menu -->
      <nav
        class="relative container px-6 py-8 mx-auto z-10 md:flex md:justify-between md:items-center"
      >
        <div class="flex items-center justify-between">
          <button
            @click="handleNavigation('#home')"
            class="text-2xl font-parisienne text-black md:text-3xl hover:text-yellow-400 whitespace-nowrap md:mr-8"
          >
            K & A
          </button>
          <!-- Mobile menu button -->
          <button
            @click="toggleMenu"
            class="flex md:hidden relative w-8 h-8 flex flex-col items-center justify-center group"
          >
            <!-- Top line -->
            <span
              class="block w-[25px] h-[3px] bg-gray-700 relative left-0 origin-left transform transition-all duration-350 ease-out"
              :class="
                isMenuOpen
                  ? 'translate-x-[15%] -translate-y-[33%] rotate-45'
                  : 'translate-x-0 translate-y-0 rotate-0'
              "
            ></span>
            <!-- Middle line -->
            <span
              class="block w-[25px] h-[3px] bg-gray-700 relative left-0 origin-left transform transition-all duration-350 ease-out mt-[5px] mb-[5px]"
              :class="isMenuOpen ? 'opacity-0' : 'opacity-100'"
            ></span>
            <!-- Bottom line -->
            <span
              class="block w-[25px] h-[3px] bg-gray-700 relative left-0 origin-left transform transition-all duration-350 ease-out"
              :class="
                isMenuOpen
                  ? 'translate-x-[15%] translate-y-[33%] -rotate-45'
                  : 'translate-x-0 translate-y-0 rotate-0'
              "
            ></span>
          </button>
        </div>

        <!-- Mobile Menu -->
        <ul
          :class="[
            'flex flex-col space-y-4 text-center md:space-y-0 md:space-x-10 md:mt-0 md:flex-row md:items-center',
            isMenuOpen
              ? 'max-h-screen opacity-100 mt-4'
              : 'max-h-0 opacity-0 mt-0',
            'overflow-hidden transition-all duration-300 ease-in-out md:max-h-none md:opacity-100',
          ]"
          class="mobile-menu"
        >
          <li>
            <button
              class="text-black text-xl font-ibmplexsemibold hover:text-yellow-600"
              @click="handleNavigation('#invitation')"
            >
              Zaproszenie
            </button>
          </li>
          <li>
            <button
              class="text-black text-xl font-ibmplexsemibold hover:text-yellow-600"
              @click="handleNavigation('#history')"
            >
              Nasza historia
            </button>
          </li>
          <li>
            <button
              class="text-black text-xl font-ibmplexsemibold hover:text-yellow-600"
              @click="handleNavigation('#attendance-confirm')"
            >
              Potwierdzenie obecności
            </button>
          </li>
          <li>
            <button
              class="text-black text-xl font-ibmplexsemibold hover:text-yellow-600"
              @click="handleNavigation('#info')"
            >
              Informacje
            </button>
          </li>
        </ul>
      </nav>
    </header>

    <!-- Big picture -->
    <section
      id="home"
      class="h-screen bg-cover bg-center relative max-h-[80vh] md:max-h-screen"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <div
        class="absolute inset-x-0 bottom-0 h-1/4 bg-gradient-to-b from-transparent to-white"
      ></div>
      <div
        class="flex items-end justify-center h-full text-center relative z-10"
      >
        <h1
          class="text-6xl md:text-8xl font-parisienne text-black md:mb-24 mb-20"
        >
          Kaja<br />&<br />Adam
        </h1>
      </div>
    </section>

    <!-- Invitation section -->
    <section
      id="invitation"
      class="bg-white p-8 flex justify-center items-center"
    >
      <div class="text-center max-w-[90%] md:max-w-[50%]">
        <h2 class="text-3xl font-ibmplexsemibold mb-8">
          Z radością zapraszamy na nasz wyjątkowy dzień!
        </h2>
        <p class="text-m md:text-xl mb-12">
          Drodzy Goście! <br />
          To dla nas ogromna radość i zaszczyt zaprosić Was do wspólnego
          świętowania początku naszej wspólnej drogi życia. Ślub i wesele to
          chwile, które chcemy przeżyć w gronie rodziny i znajomych, dzieląc się
          radością, wzruszeniami i pięknem tego wyjątkowego dnia. Wasza obecność
          uczyni te chwile jeszcze bardziej niezapomnianymi.
        </p>
        <h2 class="text-3xl font-ibmplexsemibold mb-8">Ceremonia ślubna</h2>
        <p
          class="text-xl mb-4 font-ibmplexsemibold flex items-center justify-center"
        >
          <i class="material-icons !text-[2rem] mr-3">calendar_month</i>
          7 czerwca 2025 r.
          <i class="material-icons !text-[2rem] mr-3 ml-3">access_time</i>
          15:00
        </p>
        <p class="text-xl">
          <span class="font-ibmplexsemibold">
            <i class="material-icons !text[2rem] mr-3 align-middle"
              >location_on</i
            >Katedra Poznańska - Bazylika Archikatedralna św. Piotra i Pawła<br />
          </span>
          Ostrów Tumski 17, 61-109 Poznań
        </p>
        <a
          href="https://www.google.com/maps/search/?api=1&query=Bazylika+Archikatedralna,+Ostrów+Tumski+17,+Poznań"
          target="_blank"
          rel="noopener noreferrer"
          class="text-yellow-600 underline mt-2 mb-4 block"
        >
          Zobacz na mapie
        </a>
        <p class="text-m md:text-xl mb-12">
          Ceremonia zaślubin odbędzie się w jednej z najpiękniejszych i
          najbardziej historycznych świątyń w Polsce. Katedra Poznańska położona
          na malowniczym Ostrowie Tumskim, to miejsce pełne podniosłego
          nastroju, które od wieków jest symbolem duchowości i polskiej
          historii. Jej monumentalne wnętrze i niezwykła atmosfera sprawią, że
          nasz ślub będzie wyjątkowym przeżyciem.
        </p>

        <h2 class="text-3xl font-ibmplexsemibold mb-8">Wesele</h2>
        <p class="text-xl">
          <span class="font-ibmplexsemibold">
            <i class="material-icons !text[2rem] mr-3 align-middle"
              >location_on</i
            >Pałac Jaśminowy<br />
          </span>
          Zakrzewska 15, 62-080 Batorowo
        </p>
        <a
          href="https://www.google.com/maps/search/?api=1&query=Pałac+Jaśminowy,+Zakrzewska+15,+Batorowo"
          target="_blank"
          rel="noopener noreferrer"
          class="text-yellow-600 underline mt-2 mb-4 block"
        >
          Zobacz na mapie
        </a>
        <p class="text-m md:text-xl">
          Po ceremonii ślubnej zapraszamy Was na przyjęcie weselne w Pałacu
          Jaśminowym. To niezwykłe miejsce łączy w sobie klasyczną architekturę
          i nowoczesny komfort. Otoczony zielenią i spokojem, Pałac Jaśminowy
          oferuje wyjątkową atmosferę, w której będziecie mogli cieszyć się
          pysznym jedzeniem, dobrą muzyką i niezapomnianą zabawą do białego
          rana!
        </p>
      </div>
    </section>

    <CountdownTimer />

    <section
      class="flex flex-col lg:flex-row items-center"
      :style="{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url(${marbleBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }"
    >
      <!-- Lewa strona -->
      <div
        class="bg-transparent flex-1 min-h-[200px] md:h-auto flex items-center justify-center p-4"
      >
        <img
          src="@/assets/images/hands.webp"
          alt="Placeholder Image"
          class="max-w-full h-auto rounded-lg shadow-lg"
        />
      </div>

      <div
        id="history"
        class="flex-1 bg-white flex items-center justify-center p-6 m-6 ounded-lg shadow-lg"
      >
        <div class="w-full max-w-xl text-center">
          <h2 class="text-3xl font-ibmplexsemibold mb-8">Nasza historia</h2>
          <p class="text-m md:text-xl mb-6">
            Kaja i Adam poznali się w czerwcową noc, 22.06.2018, w poznańskim
            klubie “Czekolada”, dzięki czemu wszyscy możemy mieć pewność, że Ich
            wspólna droga zawsze będzie słodka. To właśnie wtedy, wśród głośnej
            muzyki, w potężnym tłumie Adam wypatrzył Kaję. I całe szczęście,
            trochę dzięki drinkom, ale przede wszystkim dzięki swojej odwadze,
            podszedł do Niej i - choć jeszcze nie wiedzieli - zaczęli tworzyć
            razem coś wyjątkowego.
          </p>
          <p class="text-m md:text-xl mb-6">
            Na określenie ich relacji słowem “związek” nie trzeba było długo
            czekać. Siła do budowania wspólnej przyszłości złączyła Młodą Parę
            już 5 lipca 2018 roku. Czy Adam zagadująć siedemnaście dni wcześniej
            do Kajki wiedział, że to będą Jego ostatnie dni wolności - nie
            wiadomo. W każdym razie, od tego pamiętnego dnia tworzą wspólnie
            relację, na podstawie której można by napisać romantyczną książkę.
            Kaja i Adam już wtedy byli przyjaciółmi na całe życie. Od tamtego
            momentu do dnia dzisiejszego, w ich oczach zawsze widać miłość, a w
            gestach - obietnicę, że będą obok siebie w każdym momencie życia.
          </p>
          <p class="text-m md:text-xl mb-6">
            W przeciwieństwie do nazwania relacji Kaji i Adama związkiem, na
            zaręczyny przyszło nam czekać bardzo długo. Adam zdecydował się
            grzmotnąć przed Kają na kolana w sołackim parku dokładnie w piątą
            rocznicę ich związku. Kaja, dzięki Bogu, była tak wspaniałomyślna,
            że powiedziała Mu “tak”. A dzisiaj, po dwóch latach od tego momentu,
            spotykamy się wreszcie wszyscy razem, aby móc zobaczyć, jak ta
            dwójka staje się symbolem wzajemnego zaufania, szacunku i oddania.
            Więc co teraz? Teraz pozostaje tylko cierpliwie czekać wspólnie na
            owoce tej miłości…
          </p>
          <p class="text-m md:text-s mb-6">
            ~ Historię sporządziła dla Was Weronika, siostra Panny Młodej ;)
          </p>
        </div>
      </div>
    </section>

    <section
      id="attendance-confirm"
      class="flex-1 bg-white flex items-center justify-center p-6"
    >
      <div>
        <div class="w-full max-w-xl text-center">
          <h2 class="text-3xl font-ibmplexsemibold mb-4">
            Potwierdzenie obecności
          </h2>
          <p class="text-m md:text-xl mb-4">
            Drodzy Goście! <br />
            Obecność na naszym weselu możecie potwierdzić poprzez wypełnienie
            poniższego formularza lub telefonicznie:
          </p>
          <p class="text-m md:text-xl">
            <i class="material-icons !text[2rem] mr-3 align-middle">phone</i
            >Kaja:
            <a href="tel:664054556" class="hover:underline text-yellow-600"
              >664 054 556</a
            >, Adam:
            <a href="tel:535030986" class="hover:underline text-yellow-600"
              >535 030 986</a
            >
          </p>
          <p class="text-m md:text-xl mt-4">
            Prosimy o potwierdzenie obecności do
            <span class="font-ibmplexsemibold"> 1 kwietnia 2025 r.</span>
          </p>
          <form
            @submit.prevent="submitForm"
            class="space-y-4 p-6 rounded-lg shadow-lg"
          >
            <div>
              <label for="name" class="block text-m md:text-xl font-regular"
                >Imię i nazwisko</label
              >
              <input
                v-model="form.name"
                type="text"
                id="name"
                class="w-full border border-gray-300 rounded-md p-2"
                required
              />
            </div>
            <div>
              <label
                for="otherGuests"
                class="block text-m md:text-xl font-regular"
              >
                Goście towarzyszący
              </label>
              <textarea
                v-model="form.otherGuests"
                id="otherGuests"
                class="w-full border border-gray-300 rounded-md p-2"
              ></textarea>
            </div>
            <div>
              <label
                for="attendance"
                class="block text-m md:text-xl font-regular pb-2"
              >
                Czy pojawisz/pojawicie się na weselu?
              </label>
              <div class="flex items-center justify-center gap-12">
                <!-- Opcja "Tak" -->
                <label
                  for="attendance-yes"
                  class="flex items-center text-m md:text-xl"
                >
                  <input
                    v-model="form.attendance"
                    type="radio"
                    id="attendance-yes"
                    :value="true"
                    class="mr-2"
                    required
                  />
                  Tak
                </label>

                <!-- Opcja "Nie" -->
                <label
                  for="attendance-no"
                  class="flex items-center text-m md:text-xl"
                >
                  <input
                    v-model="form.attendance"
                    type="radio"
                    id="attendance-no"
                    :value="false"
                    class="mr-2"
                    required
                  />
                  Nie
                </label>
              </div>
            </div>

            <div>
              <label for="song" class="block text-m md:text-xl font-regular">
                Piosenka, do której chciałbyś/chcielibyście zatańczyć na weselu
                (wykonawca - tytuł)
              </label>
              <input
                v-model="form.song"
                id="song"
                class="w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <button
              type="submit"
              class="bg-yellow-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-yellow-400 text-m md:text-xl"
            >
              Wyślij
            </button>
            <p v-if="successMessage" class="text-yellow-600 mt-4">
              {{ successMessage }}
            </p>
            <p v-if="errorMessage" class="text-red-500 mt-4">
              {{ errorMessage }}
            </p>
          </form>
        </div>
      </div>
    </section>

    <section
      class="flex flex-col lg:flex-row-reverse items-center"
      :style="{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url(${marbleBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }"
    >
      <!-- Zdjęcie -->
      <div
        class="flex-1 flex items-center justify-center min-h-[200px] lg:h-auto p-6"
      >
        <img
          src="@/assets/images/stairs.webp"
          alt="Zdjęcie ilustrujące informacje"
          class="max-w-full h-auto rounded-lg shadow-lg"
        />
      </div>

      <!-- Tekst -->
      <div
        id="info"
        class="flex-1 bg-white flex items-center justify-center p-6 m-6 ounded-lg shadow-lg"
      >
        <div class="w-full max-w-xl text-center">
          <h2 class="text-3xl font-ibmplexsemibold mb-4">
            Informacje dla Gości
          </h2>
          <h3 class="text-2xl font-ibmplexsemibold mb-4">
            Zamiast kwiatów i prezentów...
          </h3>

          <img
            src="@/assets/images/present_icons.svg"
            alt="Prezenty"
            class="w-2/3 mx-auto"
          />

          <p class="text-m md:text-xl mb-4">
            Kwiaty są piękne, ale szybko więdną. Prezenty są wspaniałe, ale
            nasze szafki i tak już pękają w szwach. <br />
            Dlatego z całego serca prosimy, abyście:
          </p>
          <ul class="list-disc list-inside text-left text-m md:text-xl mb-6">
            <li class="mb-4">
              Zamiast kwiatów przynieśli zdrapki Lotto - bo kto wie, może to
              właśnie w dniu ślubu los się do nas uśmiechnie!
            </li>
            <li>
              Zamiast tradycyjnych prezentów rozważyli wkład pieniężny do naszej
              „skarbonki marzeń”, z której środki przeznaczymy na budowę naszego
              gniazdka 🏡 i niezapomniane wakacje! 🏝️
            </li>
          </ul>
          <p class="text-m md:text-xl mb-4">
            Jeśli preferujecie pieniężną formę wsparcia, a gotówka jest dla Was
            niewygodna, udostępniamy również nasz numer konta:
          </p>
          <p class="text-m md:text-xl mb-4">
            <strong>95 1020 4027 0000 1602 1533 7424</strong>
          </p>
          <p class="text-m md:text-xl">
            Dziękujemy Wam za każdy gest i przede wszystkim za Waszą obecność w
            tym wyjątkowym dniu! 💛
          </p>
          <h3 class="text-2xl font-ibmplexsemibold mb-4 mt-4">
            Dodatkowa prośba!
          </h3>
          <p class="text-m md:text-xl">
            Prosimy również o przyniesienie Waszego ulubionego zdjęcia z nami.
            Będziecie mogli je umieścić w naszej księdze gości. 📸
          </p>
        </div>
      </div>
    </section>

    <section id="footer">
      <div class="bg-white p-6 text-right">
        <p class="text-m md:text-xl">© 2025 Kaja & Adam 💛</p>
      </div>
    </section>
  </div>
</template>

<script>
import { supabase } from "@/supabase";
import CountdownTimer from "./components/CountdownTimer.vue";
export default {
  components: {
    CountdownTimer,
  },
  name: "App",
  data() {
    return {
      isMenuOpen: false,
      backgroundImage: require("@/assets/images/banner.webp"),
      marbleBackground: require("@/assets/images/marble_background.webp"),
      presentIcons: require("@/assets/images/present_icons.svg"),
      form: {
        name: "",
        song: "",
        attendance: true,
        otherGuests: "",
      },
      successMessage: "",
      errorMessage: "",
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    handleNavigation(target) {
      this.toggleMenu();

      const element = document.querySelector(target);

      if (element) {
        const menuHeight = document.querySelector(".mobile-menu").offsetHeight;

        const headerHeight = document.querySelector("header").offsetHeight;

        const elementPosition = element.offsetTop - headerHeight + menuHeight;

        window.scrollTo({
          top: elementPosition,
          behavior: "smooth",
        });
      }
    },
    resetForm() {
      this.form = {
        name: "",
        song: "",
        attendance: true,
        otherGuests: "",
      };
    },
    async submitForm() {
      if (this.form.attendance === null || this.form.attendance === undefined) {
        this.errorMessage = "Proszę wybrać, czy będziesz obecny na weselu.";
        return;
      }

      const { error } = await supabase.from("guests").insert([
        {
          name: this.form.name,
          otherGuests: this.form.otherGuests,
          attendance: this.form.attendance,
          song: this.form.song,
        },
      ]);

      if (error) {
        this.errorMessage = "Wystąpił problem podczas wysyłania formularza.";
        console.error(error.message);
      } else {
        this.successMessage = "Dziękujemy za potwierdzenie obecności!";
        this.resetForm();
      }
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "IBMPlexSerif", Arial, sans-serif;
  scroll-behavior: smooth;
}
@font-face {
  font-family: "Parisienne";
  src: url("https://fonts.cdnfonts.com/s/15432/Parisienne-Regular.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBMPlexSerif";
  src: url("https://fonts.cdnfonts.com/s/15353/IBMPlexSerif-Regular.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBMPlexSerifSemiBold";
  src: url("https://fonts.cdnfonts.com/s/15353/IBMPlexSerif-SemiBold.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
</style>
