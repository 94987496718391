<template>
  <div class="text-center p-6 mb-8">
    <h2 class="text-3xl font-ibmplexsemibold mb-4">
      Będziemy świętować już za:
    </h2>
    <div class="text-2xl lg:text-4xl font-bold">
      {{ timer.days }} dni : {{ timer.hours }} godzin :
      {{ timer.minutes }} minut : {{ timer.seconds }} sekund
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      targetDate: new Date("2025-06-07T15:00:00").getTime(),
      timer: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },
  mounted() {
    this.updateTimer();
    this.interval = setInterval(this.updateTimer, 1000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    updateTimer() {
      const now = new Date().getTime();
      const distance = this.targetDate - now;

      if (distance > 0) {
        this.timer.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.timer.hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        this.timer.minutes = Math.floor(
          (distance % (1000 * 60 * 60)) / (1000 * 60)
        );
        this.timer.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      } else {
        clearInterval(this.interval);
        this.timer = { days: 0, hours: 0, minutes: 0, seconds: 0 };
      }
    },
  },
};
</script>
